import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <a href="https://www.davidebalice.dev" target="_blank" rel="noreferrer">
        www.davidebalice.dev
      </a>
    </footer>
  );
};

export default Footer;
